<template>
    <div class="policies">
        <v-container>
            <v-row>
                <v-col cols="12" align="center">
                    <h1>
                        {{policy.title}}
                    </h1>
                </v-col>
                <v-col>
                    <div class="content" v-html="policy.content"></div>
                </v-col>
            </v-row>
        </v-container>


    </div>
</template>
<script>
export default {
    data(){
        return {
            policy: {}
        }
    },
    beforeMount: function(){
        fetch('https://rawkv3.actstudio.xyz/api/content/items/Policies?filter={page:"Envios y Devoluciones"}', {
            method: 'GET'
        })
        .then(res => res.json())
       // .then(res => res.filter((elem, index) => elem.page === 'Envios y Devoluciones'))
        .then(res => this.policy = res[0])
        .catch((error) => {
            console.error('Error:', error);
        });
    }
}
</script>